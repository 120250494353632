//定义英文语言包
export default {
    index:{
        s1:"One minute tutorial",
        s2:"Rules of round-eyed",
        s3:"Share to become VIP",
        s31:"VIP can set table background image,can also enjoy some conveniences",
        s4:"Players Ranking",
        s5:"Games Ranking",
        s6:"Advice/Report",
        s7:`This is not a gambling web. Coins can't be exchanged for cash. Just for fun!`,
        s8:'Coins',
        s9:'Number',
        s10:'Coins',
        s11:'Profit',
        s12:'Date'
    },
    rules:{
        s0:"Overview",
        s1:"Round-eyed is a poker game from China, originally named Gan Deng Yan.",
        s2:"Players play cards based on figures.E.g.,someone play 3,you must play 4, then another person should play 5.",
        s3:"The one who play all his/her cards first is winner.",
        s4:"You can play cards in the following ways:",
        s5:"Single card",
        s6:"Someone play 3,you must play 4,and so on.If you don't have 4,you can play 2.",
        s7:"2 can end any other single card.Bomb can end any single card.",
        s8:"Pair",
        s9:"Someone play 33,you must play 44,and so on.If you don't have 44,you can play 22.",
        s10:"22 can end any other pair.Bomb can end any pair.",
        s11:"Straight",
        s12:"Someone play 456,you must play 567,and so on.",
        s13:"Bomb can end any straight.",
        s14:"Bomb",
        s15:"Cards like 333,4444 are bombs.Bombs can end any single card / pair / straight.",
        s16:"444 can end 333,5555 can end 666. Two Jokes can end any other bomb,so can three jokes.",
        s17:"Joker",
        s18:"Joker can replace any other single card.",
        s19:"You can consider the cards above as pair 4 and straight 456.",
        s20:"But you can never play a joker as single card,unless you only have one card.",
        s21:"Win / Lose",
        s22:"The one who play all his/her cards first is winner.",
        s23:"Other players are loser.Loser will lose coins equal to the number of her/his left cards multiplied by 10.",
        s25:"All losses of the losers belong to the winners.",
        s26:"Double",
        s27:"If a bomb appears in the game,everyone's winnings or losses will be doubled.If two bombs, that will befour times.",
        s28:"When the game ends, if you haven't played a card,your losses will be doubled.",
        s29:"So you should strive to play at least one card",
        s30:"Ante",
        s31:"Once the game begins,100 coins will be deducted for ante,they wiil be return back at the end of game."
    },
    login:{
        s1:"Email:",
        s2:"Password:",
        s3:"Register",
        s4:"Login",
        s5:"Home",
        s6:"Nickname:",
        s7:"Forget password?Click here"
    },
    coinget:{
        s1:"Your coins:",
        s2:"Click button left to become VIP.You will receive 5000 coins at once. It costs $10 for one year.",
        s2a:"VIP can set table background image,also can view history without limit in tarneeb.",
        s3:"Click button left to get Relief funds.You can get a relief fund of 500 coins(Vip can get 1000) for free once a day.Your coins should less than",
        s4:"10000 coins for $3.Vip can get 15000."
    }
}