import { createRouter, createWebHashHistory } from 'vue-router'

const Home=()=>import('@/views/home/index.vue')
const Login = ()=>import('@/views/user/login.vue') 
const DenDen=()=>import('@/views/denden/index.vue')
const User=()=>import('@/views/user/index.vue')
const Useredit=()=>import('@/views/user/edit.vue')
const Replay=()=>import('@/views/denden/replay.vue')
const Note=()=>import('@/views/denden/note.vue')
const Teach=()=>import('@/views/denden/teach.vue')
const Rules=()=>import('@/views/home/rules.vue')
const Coinget=()=>import('@/views/user/coinget.vue')
const Userrank=()=>import('@/views/home/userrank.vue')
const Admin=()=>import('@/views/admin/index.vue')
const Advice_adm=()=>import('@/views/admin/advice.vue')
const Gamerank=()=>import('@/views/home/gamerank.vue')
const Getpass=()=>import('@/views/user/getpass.vue')
const Shareact=()=>import('@/views/home/activity_share.vue')
const Setvip=()=>import('@/views/admin/setvip.vue')
const Repairing=()=>import('@/views/home/repairing.vue')
const Playtb=()=>import('@/views/tarneeb/index.vue')
const Replaytb=()=>import('@/views/tarneeb/replay.vue')
const Teachtb=()=>import('@/views/tarneeb/teach.vue')
const Rulestb=()=>import('@/views/home/rulestb.vue')

const routes = [
  //一级路由
  { path:'/',component:Home,name:"index",meta:{ title:"pooker" } },
  { path: '/play', component: DenDen,name:"play",meta:{ title:"play round-eyed" } },
  { path: '/user', component: User,name:"user",meta:{ title:"round-eyed user" } },
  { path: '/useredit', component: Useredit,name:"useredit",meta:{ title:"user edit" } },
  { path: '/replay', component: Replay,name:"replay",meta:{ title:"round-eyed replay" } },
  { path: '/note', component: Note,name:"note" },
  { path: '/teach', component: Teach,name:"teach",meta:{ title:"round-eyed tutorial" } },
  { path: '/rules', component: Rules,name:"rules",meta:{ title:"round-eyed rules" } },
  { path: '/coinget', component: Coinget,name:"coinget",meta:{ title:"round-eyed coins" } },
  { path: '/ranku', component: Userrank,name:"ranku",meta:{ title:"round-eyed rank" } },
  { path: '/rankg', component: Gamerank,name:"rankg",meta:{ title:"round-eyed rank" } },
  { path: '/guanli', component: Admin,name:"guanli" },
  { path: '/guanli_adv', component: Advice_adm,name:"guanli_adv" },
  { path: '/guanli_vip', component: Setvip,name:"guanli_vip" }, 
  { path:'/login',component:Login,name:"login",meta:{ title:"round-eyed login" } },
  { path:'/getpass',component:Getpass,name:"getpass",meta:{ title:"round-eyed getpass" } },
  { path: '/shareact', component: Shareact,name:"shareact" },
  { path: '/repairing', component: Repairing,name:"repairing" },
  { path: '/playtb', component: Playtb,name:"playtb",meta:{ title:"play tarneeb" } },
  { path: '/replaytb', component: Replaytb,name:"replaytb",meta:{ title:"tarneeb replay" } },
  { path: '/teachtb', component: Teachtb,name:"teachtb",meta:{ title:"tarneeb tutorial" } },
  { path: '/rulestb', component: Rulestb,name:"rulestb",meta:{ title:"tarneeb rules" } }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  // 每次切换时都滚动到页面顶部
  scrollBehavior(){
    return {left:0,top:0}
  }
})

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title == undefined ? 'round-eyed' : to.meta.title
  next();
})

// router.beforeEach((to, from) => {
//   // 监听路由变化之前的操作  注意：这里是全局的 不建议用全局的
//   // to.matched.some(record => record.meta.requiresAuth)
//   if(to.name === "rules"){
//     const { type} = to.params || {};
//     to.meta.title = 'denden rules'
//     to.meta = {};
//   }
// })

export default router
