import { createStore } from 'vuex' 
import creatPersistedState from 'vuex-persistedstate'

import user from './modules/user'
import lang from './modules/lang'

export default createStore({
  modules: {
    user,lang
  },
  plugins:[
    creatPersistedState({
      //本地存储名字
      key:"poo-store",
      //指定要存储的模块
      paths:['user','lang']
    })
  ]
})
