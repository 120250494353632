import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n';
import en from './language/en';
import zh from './language/zh';

//自己写的重置样式
import '@/assets/styles/common.less'
// import { createHead } from '@unhead/vue'

// 组合语言包对象
const messages = {
    en,
    zh
}

//语言不是简中，就用英语
let lang = navigator.language
if(lang!="zh-CN")lang='en'

// 创建实例对象
const i18n = createI18n({
    legacy: false,  // 设置为 false，启用 composition API 模式
    messages,
    locale: lang
})

// const head = createHead()

createApp(App).use(store).use(router).use(i18n).mount('#app') 
// createApp(App).use(store).use(router).use(i18n).use(head).mount('#app') 
