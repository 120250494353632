//用户模块
import { today_format } from '@/utils/myutils'

export default {
    namespaced: true,
    state() {
        return {
            profile: {
                id: 0,
                name: '',
                token: '',
                pic: '',
                coins: 0,
                dendennum: 0,
                tarneebnum:0,
                vip: 0,
                seniordaily: -1,
                bg:''    //vip自定义的背景图片
            },
            lastlogin: 0,   //上一个登录的用户id
            trialnum: 3,  //试玩次数 到0就不能试玩
            relieflog: []  //数组 记录用户领救济金的日期  [{id,date},{id2,date}]
        }
    },
    getters: {      //使用 store.getters.getTodoById(2)
        hasgetrelief: (state) => {
            let index = state.relieflog.findIndex(v => v.id == state.profile.id)
            if (index < 0) return false
            if (state.relieflog[index].date && state.relieflog[index].date == today_format()) return true
            return false
        }
    },
    mutations: {
        //修改用户信息
        setUser(state, payload) {
            state.profile = payload
            if (payload.id) {  //记录上一个登录的用户id
                state.lastlogin = payload.id
            }
        },
        //修改用户头像
        setUserPic(state, pic) {
            state.profile.pic = pic
        },
        //修改用户金币数
        setUserCoins(state, coins) {
            state.profile.coins = parseInt(coins)
        },
        //zengj用户金币数
        addUserCoins(state, coins) {
            state.profile.coins += parseInt(coins)
        },
        //修改用户对局数
        setUserNUm(state, num) {
            state.profile.dendennum = num
        },
        //修改用户tarneeb对局数
        setUserTNUm(state, num) {
            state.profile.tarneebnum = num
        },
        //试玩次数减一
        trialnumminus(state) {
            state.trialnum--
        },
        //设置用户vip
        setUserVip(state, vip) {
            state.profile.vip = vip
        },
        //设置背景图片
        setUserBg(state, bg){
            state.profile.bg = bg
        },
        //设置用户 普通玩家每日高级牌局数
        setUserSeniordaily(state, sd) {
            let time = new Date()
            let year = time.getFullYear()
            let month = time.getMonth() + 1
            let day = time.getDate()
            let todaystr = year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day)
            if(!sd){
                state.profile.seniordaily=0
            }else{
                let sds = sd.split("_")
                if(sds[0]==todaystr){
                    state.profile.seniordaily=parseInt(sds[1])
                }else{
                    state.profile.seniordaily=0
                }
            }
        },
        //记录领救济金日期
        reliefrecord(state) {
            if (state.profile.id) {
                let index = state.relieflog.findIndex(v => v.id == state.profile.id)
                if (index < 0) {
                    state.relieflog.push({ id: state.profile.id, date: today_format() })
                } else {
                    state.relieflog[index].date = today_format()
                }
            }
        }
    }
}